import * as React from "react";

import * as styles from "./index.module.scss";
import TARIFFS_LIST from "../../../constants/Pricing";

import Tariff from "./components/Tariff";
import CarouselPricing from "../../../components/CarouselPricing";

const Tariffs = () => {
    return (
        <>
            <div className={styles.sectionWrapper}>
                <div className={styles.tariffsWrapper}>
                {TARIFFS_LIST.map((tariff, index) => (
                    <Tariff
                        cardLabel={tariff?.cartLabel}
                        title={tariff.title}
                        subTitle={tariff.subTitle}
                        cost={tariff.cost}
                        featuresList={tariff.featuresList}
                        key={index}
                    />
                ))}
                </div>

            </div>
            <div className={styles.tariffsSliderWrapper}>
                <CarouselPricing/>
            </div>
        </>
    );
};

export default Tariffs;
