// extracted by mini-css-extract-plugin
export var button = "index-module--button--9nxAO";
export var buttonWrapper = "index-module--button-wrapper--bU7oZ";
export var cardLabel = "index-module--card-label--ZED9i";
export var cost = "index-module--cost--zTYV9";
export var costWrapper = "index-module--cost-wrapper--dzyFJ";
export var currencySymbol = "index-module--currency-symbol--DL1Ta";
export var featuresList = "index-module--features-list--D+EeE";
export var perMonth = "index-module--per-month--D1Q-t";
export var subTitle = "index-module--sub-title--wcjZl";
export var title = "index-module--title--nyFiP";
export var titleWrapper = "index-module--title-wrapper--26eys";
export var titleWrapperWithMargin = "index-module--title-wrapper-with-margin--F-7EN";
export var underline = "index-module--underline--0-YdJ";
export var wrapper = "index-module--wrapper--ko6XS";