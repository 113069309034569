const TARIFFS_LIST = [
  {
    title: "Starter",
    subTitle: "Membership",
    cost: "35",
    featuresList: [
      "100 Active listings",
      "50 Drafts",
      "Ebay, Poshmark, Etsy, Mercari",
      "No Employee Invites",
    ],
  },
  {
    cartLabel: "Most Popular",
    title: "Business",
    subTitle: "Membership",
    cost: "50",
    featuresList: [
      "500 Active listings",
      "100 Drafts",
      "Ebay, Poshmark, Etsy, Mercari",
      "1 Employee Invite",
    ],
  },
  {
    cartLabel: "Best Value",
    title: "Unlimited",
    subTitle: "Membership",
    cost: "65",
    featuresList: [
      "Unlimited Active Listings",
      "Unlimited Drafts",
      "Ebay, Poshmark, Etsy, Mercari",
      "Unlimited Employee Invites",
    ],
  },
];

export default TARIFFS_LIST;
