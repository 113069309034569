import * as React from "react";

import * as styles from "./index.module.scss";

import BackgroundImage from "../../../components/BackgroundImage";
import SectionLayout from "../../../components/SectionLayout";
import Tariffs from "../Tariffs";
import pricing from "../../../images/pricing.png";
import Svg from "../../../images/faq-banner.inline.svg";

const PricingSection = () => {
  return (
    <SectionLayout className={styles.sectionWrapper}>
      <BackgroundImage
          backgroundImageDesktop={pricing}
          backgroundImageTablet={pricing}
          backgroundImageMobile={pricing}
          imageStyle={styles.backgroundImage}
          Svg={Svg}
          alt="Banner
          ">
        <div className={styles.backgroundImageChildren}>
          <span>Pricing</span>
        </div>
      </BackgroundImage>
      <Tariffs />
    </SectionLayout>
  );
};

export default PricingSection;
