// extracted by mini-css-extract-plugin
export var button = "index-module--button--YXyDc";
export var buttonWrapper = "index-module--button-wrapper--EW+dr";
export var cardLabel = "index-module--card-label--jt1xz";
export var cost = "index-module--cost--qGdsE";
export var costWrapper = "index-module--cost-wrapper--wzQP5";
export var currencySymbol = "index-module--currency-symbol--WlVYB";
export var featuresList = "index-module--features-list--DssLr";
export var perMonth = "index-module--per-month--8XkMe";
export var subTitle = "index-module--sub-title--JD0-M";
export var title = "index-module--title--wnxDK";
export var titleWrapper = "index-module--title-wrapper--aK--U";
export var titleWrapperWithMargin = "index-module--title-wrapper-with-margin--ZG9ZA";
export var underline = "index-module--underline--skm9B";
export var wrapper = "index-module--wrapper--TcaO9";