import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import PricingSection from "../../sections/Pricing/PricingSection";
import Footer from "../../components/Footer";

const PageContent = () => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return (
    <>
      <PricingSection />
      <Footer />
    </>
  );
};

const Pricing = () => {
  return (
    <>
      <Header />
      <PageLayout title="Pricing">
        <PageContent />
      </PageLayout>
    </>
  );
};

export default Pricing;
